import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'

import { useUser } from 'hooks'
import isBrowser from 'utils/isBrowser'

import './Header.scss'

import logo from 'assets/logo.svg'
import userPhotoPlaceholder from './assets/user-photo-placeholder.svg'

export default function Header(): ReactElement {
  const { pathname } = useLocation()
  const { isAuthenticated, isLoaded, data } = useUser()

  let firstName: string | undefined,
    lastName: string | undefined,
    photo: string | undefined,
    userName: string | undefined

  if (data) {
    firstName = data.firstName
    lastName = data.lastName
    photo = data.photo
    userName = firstName.length >= 4 ? firstName : `${firstName} ${lastName}`
  }

  return (
    <header className="header">
      <div className="row">
        <div className="col-10 col-sm-6 col-lg-4 col-xl-3">
          {/* Old Logo to /Dashboard */}
          {/* 
            <Link className="header__logo" to="/dashboard">
              <img src={logo} alt="Jevitty Logo" />
            </Link> 
          */}
          {/* Logo to Web Jevitty App */}
          <a className="header__logo" href="https://web.jevitty.com" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Jevitty Logo" />
          </a>
        </div>
        <nav className="header__nav header__nav--account col-2 col-sm-6 col-md-auto order-lg-3 ms-auto">
          {isAuthenticated ? (
            isLoaded && (
              <>
                <Link
                  className="header__nav__item header__nav__item--with-avatar"
                  activeClassName="header__nav__item--active"
                  to="/profile"
                >
                  <>
                    <img
                      className="header__nav__item__avatar"
                      src={photo || userPhotoPlaceholder}
                      alt={`${firstName} ${lastName}`}
                    />
                    <span className="header__nav__item__text">{userName}</span>
                  </>
                </Link>
              </>
            )
          ) : pathname.includes('dashboard') ||
            pathname.includes('reset-password') ||
            pathname.includes('verify-email') ||
            !isBrowser() ? null : (
              // Old Link to Dashboard
            // <Link
            //   className="header__nav__item"
            //   activeClassName="header__nav__item--active"
            //   to="/dashboard"
            // >
            //   Sign In
            // </Link>
            
            // New Link to Web Jevitty
            <a className="header__nav__item" href="https://web.jevitty.com" target="_blank" rel="noopener noreferrer">
              Sign In
            </a>
          )}
        </nav>
        <nav className="header__nav header__nav--menu col col-lg-auto order-lg-2">
          {isAuthenticated && (
            <>
              <Link
                className="header__nav__item"
                activeClassName="header__nav__item--active"
                partiallyActive
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                className="header__nav__item"
                activeClassName="header__nav__item--active"
                partiallyActive
                to="/marketplace"
              >
                Marketplace
              </Link>
              <Link
                className="header__nav__item"
                activeClassName="header__nav__item--active"
                partiallyActive
                to="/news/"
              >
                News
              </Link>
            </>
          )}
        </nav>
      </div>
    </header>
  )
}
